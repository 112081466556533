import React from "react";
import { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useUser } from "../auth/useUser";
import { useUserData } from "../auth/useUserData";

import "./css/EventList.css"

import RatingsListTable from "./components/RatingsListTable";

let baseUrl = "";
if (process.env.REACT_APP_NODE_ENV === "production") {
  baseUrl = "mongodbprod.fencingleagueofamerica.org:4443";
} else {
  baseUrl = "mongodb01.fencingleagueofamerica.org:4443";
}

// https://stackoverflow.com/questions/55521912/multiple-fetch-data-axios-with-react-hooks

// the above is for multiple fetching!!!!!!!!!!



export const RatingsPage = ({ classAct }) => {
  const user = useUser();
  console.log("RatingsPage - user: ", user);
  console.log("RatingsPage - classAct: ", classAct);

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [ratings, setRatings] = useState([]);
  const [isSkipp, setIsSkipp] = useState(false);
  const [aauMemberNumber, setAauMemberNumber] = useState('');






  const { id, email, isVerified, info, tobi } = user; // destructuring assignment

  console.log("@@ >> destructured id: " + id + " email: " + email + " isVerified: " + isVerified);
  console.log("@@ >> destructured info: " + JSON.stringify(info) + " tobi: " + tobi + " << @@");

  // We'll use the history to navigate the user
  // programmatically. after push, we'd need go() for forced refresh.
  const history = useHistory();
  const userData = useUserData(id);
  console.log(" @@@@@@ >> RatingsPage - userData Loaded: " + JSON.stringify(userData) + " << @@@@@@ ");
  const {aauNumber} = userData;
  console.log(" @@@@@@ >> RatingsPage - aauNumber DECONST: " + aauNumber + "!!!");
  //setAauMemberNumber(aauNumber);

  const { errorMessage } = userData;
  if (errorMessage === "bad request?") {
    console.log(" @@@@@@ >> RatingsPage - SERIOUS ISSUE detected!");
    // issues with id in DB. either user no longer exists or other serious issue...
    history.push("/logout");
  }




  //console.log("  @@@@@ UserProfilePage - info.bio: "+info.bio)

  useEffect(() => {
    const loadRatings = async () => {
      console.log("RatingsPage - useEffect");
      try {
        const myUrl = `https://${baseUrl}/api/ratings/`+aauNumber;
        if (aauNumber === undefined) {console.log("+=+=+=+=+=+=+=+=+=+= RatingsPage - aauNumber is undefined");}
        if (aauNumber === null) {console.log("+=+=+=+=+=+=+=+=+=+= RatingsPage - aauNumber is null");}

        //const response = await axios.get(`https://${baseUrl}/api/ratings`);
        const response = await axios.get(myUrl);
        console.log("+=+=+=+=+=+=+=+=+=+= RatingsPage - myUrl: ", myUrl);
        console.log("+=+=+=+=+=+=+=+=+=+= RatingsPage - response: ", response);
        const responseData = response.data.message;
        console.log(" RatingsPage - responseData: ", responseData);


        if (responseData !== "No data found.") {
          var count = responseData.reduce((p, c) => {
            var event = c.EVENT_EARNED;
            if (!p.hasOwnProperty(event)) {
              p[event] = 0;
            }
            p[event]++;
            return p;
          }, {});

          //let countResult = _.countBy(Ratings, item => item.EVENT);
          console.log("+=+=+=+=+=+=+=+=+=+= RatingsPage - count: ", count);
          console.log("+=+=+=+=+=+=+=+=+=+= RatingsPage - responseData: ", responseData);
        }


        if (responseData === "No data found.") setIsSkipp(true);
        setRatings(responseData);
        setIsSuccess(true);
        setIsLoading(false);



      } catch (e) {
        setIsSuccess(false);
        setIsLoading(false);
      }
    };

    if (aauNumber !== undefined && aauNumber !== null)
      loadRatings();
  //}, [user]);
  }, [aauNumber]);
  //}, [aauMemberNumber]);

  const columns = useMemo(
    () => [
      {
        Header: "ratings",
        columns: [
          {
            Header: "Events",
            //accessor: "row.row.original.data.eventId",
            accessor: "row.row.original.EVENT",
            //Cell: ({ value }) => {
            Cell: (row) => {
              //let value = row.row.original.data.eventId;
              let value = row.row.original.EVENT;
                  /////////// within the return ()
                  //<Link to={{ pathname: `/eventpayment/${value}` }} >
                  //  {value}
                  //</Link>
              return (
                <>
                    {value}
                </>
              );
            },
          },

          {
            Header: "Event Date",
            accessor: "row.row.original.EVENT_DATE",
            //Cell: ({ value }) => {
            Cell: (row) => {
              let value = row.row.original.EVENT_DATE;
              return (
                <>
                    {value}
                </>
              );
            },
          },

          {
            Header: "Tournament",
            accessor: "row.row.original.TOURNAMENT_NAME",
            //Cell: ({ value }) => {
            Cell: (row) => {
              let value = row.row.original.TOURNAMENT_NAME;
              return (
                <>
                    {value}
                </>
              );
            },
          },

          {
            Header: "Earned",
            accessor: "row.row.original.EARNED",
            //Cell: ({ value }) => {
            Cell: (row) => {
              let value = row.row.original.EARNED;
              return (
                <>
                    {value}
                </>
              );
            },
          },

          {
            Header: "Rated",
            //accessor: "row.row.original.EARNED",
            //Cell: ({ value }) => {
            Cell: (row) => {

              let value = row.row.original.EARNED;


              switch (value) {
                case 'A':
                console.log(' @@ RatingsPage - value is A');
                if (row.row.original.A === undefined) { var myDispRating = value+'1'}
                else {
                var myDispRating = value+row.row.original.A;
                }
                break;
                case 'B':
                console.log(' @@ RatingsPage - value is B');
                if (row.row.original.B === undefined) { var myDispRating = value+'1'}
                else {
                var myDispRating = value+row.row.original.B;
                }
                break;
                case 'C':
                console.log(' @@ RatingsPage - value is C');
                if (row.row.original.C === undefined) { var myDispRating = value+'1'}
                else {
                var myDispRating = value+row.row.original.C;
                }
                break;
                case 'D':
                console.log(' @@ RatingsPage - value is D');
                if (row.row.original.D === undefined) { var myDispRating = value+'1'}
                else {
                var myDispRating = value+row.row.original.D;
                }
                break;
                case 'E':
                console.log(' @@ RatingsPage - value is E');
                if (row.row.original.E === undefined) { var myDispRating = value+'1'}
                else {
                var myDispRating = value+row.row.original.E;
                }
                break;
                default:
                console.log(' @@ RatingsPage - value is ', value);
                var myDispRating = 'U';
                }




              return (
                <>
                    {myDispRating}
                </>
              );
            },
          },

        ],
      },
    ],
    []
  );

  if (isLoading) return <p>Loading...</p>;
  if (isSkipp) return <p>No Ratings found... If you think this is in error, please contact support desk.</p>;
  if (isSuccess) {
    console.log(" RatingsPage - ratings: ", ratings);

    return (
      <div className="container lh-sm fw-light px-5 mt-4">
        <h1>{info.first} {info.middle} {info.last} (AAU: {aauNumber}) Ratings</h1>
        <div className="row">
          <div className="col-12">
            {console.log(" @@ RatingsPage - ratings: ", ratings)}
            {console.log(" @@ RatingsPage - ratings.length: ", ratings.length)}
            {console.log(" @@ RatingsPage - ratings.success: ", ratings.success)}
            {ratings ? <RatingsListTable columns={columns} data={ratings} /> : <p>No transactions found.</p>}
          </div>
          <a role="button" className="btn btn-primary mt-4" href="/main">
            back
          </a>
        </div>
      </div>
    );
  } else {
    return <p className="text-danger bg-warning">... Could not find Rating/Ranking data. Have you register your AAU# in your profile?<br/>Also verify the AAU#.</p>;
  }
};
//export default EventList;
export default RatingsPage;