export const getAgeName = (props) => {
  switch (props.toUpperCase()) {
    case 'Y8':
      return 'Y-8'
    case 'Y10':
      return 'Y-10'
    case 'Y14':
      return 'Y-14'
    case 'CDT':
      return 'Cadet'
    case 'JNR':
      return 'Junior'
    case 'SNR':
      return 'Senior'
    case 'VET':
      return 'Veteran'
    case 'PRM':
      return 'Prime'
    default:
      console.log('>> getAgeName props: ', props)
      return props
  }
}

export const getGenderName = (props) => {
  switch (props.toUpperCase()) {
    case 'MX':
      return 'Mix'
    case 'M':
      return 'Men'
    case 'F':
      return 'Female'
    default:
      console.log('>> getGenderName props: ', props)
      return props
  }
}

export const getWeaponName = (props) => {
  switch (props.toUpperCase()) {
    case 'E':
      return 'Epée'
    case 'S':
      return 'Saber'
    case 'F':
      return 'Foil'
    case 'H':
      return 'HEMA'
    default:
      console.log('>> getWeaponName props: ', props)
      return props
  }
}

export const getDigest = (A, B, C, D, E) => {
  console.log('>> getDigest props: ', A, B, C, D, E)
  let digest = ''
  //{A === '0' ? '-' : A}
  if (A !== '0') digest = 'A' + A
  if (B !== '0') digest = digest + '  B' + B
  if (C !== '0') digest = digest + '  C' + C
  if (D !== '0') digest = digest + '  D' + D
  if (E !== '0') digest = digest + '  E' + E
  if (digest === '') digest = '-'
  return digest
}

export const getFullEarned = (A, B, C, D, E) => {
  console.log('>> getFullEarned props: ', A, B, C, D, E)
  let earned = ''
  //{A === '0' ? '-' : A}
  earned = 'A' + A
  earned += ' B' + B
  earned += ' C' + C
  earned += ' D' + D
  earned += ' E' + E
  if (earned === '') earned = '-'
  return earned
}

export const isOver = (startDate, eventDays = 0) => {
  //
  const current = new Date()
  let eventEndDate = new Date(startDate)
  eventEndDate.setDate(eventEndDate.getDate() + eventDays)
  console.log('@@@@ isOver - startDate:', startDate)
  console.log('@@@@ isOver - eventDays:', eventDays)
  console.log('@@@@ isOver - eventEndDate:', eventEndDate)
  console.log('@@@@ isOver - current:', current)

  if (eventEndDate.getTime() > current.getTime()) {
    console.log('@@@@ isOver - eventEndDate is not expired - not OVER')
    return 0 // is in the future
  } else {
    console.log('@@@@ isOver - eventEndDate is in the past - is OVER')
    return 1 // is in the past
  }
}
