import React from "react";
import { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useUser } from "../auth/useUser";
import { useUserData } from "../auth/useUserData";

import "./css/EventList.css"

let baseUrl = "";
if (process.env.REACT_APP_NODE_ENV === "production") {
  baseUrl = "mongodbprod.fencingleagueofamerica.org:4443";
} else {
  baseUrl = "mongodb01.fencingleagueofamerica.org:4443";
}

// https://stackoverflow.com/questions/55521912/multiple-fetch-data-axios-with-react-hooks

// the above is for multiple fetching!!!!!!!!!!



export const RatingsPage3 = (props) => {
  const user = useUser();

  // https://stackoverflow.com/questions/65678961/react-route-params-with-default-value
  const { weapon = 'E' } = useParams(); // default value to be 'E' for now
  console.log("RatingsPage3 - user: ", user);
  console.log(" @ RatingsPage3 - props: ", props);
  console.log(" @ RatingsPage3 - weapon: ", weapon);

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [ratings, setRatings] = useState([]);
  const [isSkipp, setIsSkipp] = useState(false);
  //const [aauMemberNumber, setAauMemberNumber] = useState('');

  const BASE_ROUTE = "/ratings3";
  var myWeapon = '';






  const { id, email, isVerified, info, tobi } = user; // destructuring assignment

  console.log("@@ >> destructured id: " + id + " email: " + email + " isVerified: " + isVerified);
  console.log("@@ >> destructured info: " + JSON.stringify(info) + " tobi: " + tobi + " << @@");

  // We'll use the history to navigate the user
  // programmatically. after push, we'd need go() for forced refresh.
  const history = useHistory();
  const userData = useUserData(id);
  console.log(" @@@@@@ >> RatingsPage3 - userData Loaded: " + JSON.stringify(userData) + " << @@@@@@ ");
  const {aauNumber} = userData;
  console.log(" @@@@@@ >> RatingsPage3 - aauNumber DECONST: " + aauNumber + "!!!");
  //setAauMemberNumber(aauNumber);

  const { errorMessage } = userData;
  if (errorMessage === "bad request?") {
    console.log(" @@@@@@ >> RatingsPage3 - SERIOUS ISSUE detected!");
    // issues with id in DB. either user no longer exists or other serious issue...
    history.push("/logout");
  }




  //console.log("  @@@@@ UserProfilePage - info.bio: "+info.bio)

  useEffect(() => {
    const loadRatings = async () => {
      console.log("RatingsPage3 - useEffect");
      try {
        // path: '/api/ratingsweapon/:AAUNum/:weapon?',
        //const myUrl = `https://${baseUrl}/api/ratings/`+aauNumber;
        const myUrl = `https://${baseUrl}/api/ratingsweapon/`+aauNumber+`/`+weapon.toUpperCase();
        if (aauNumber === undefined) {console.log("+=+=+=+=+=+=+=+=+=+= RatingsPage3 - aauNumber is undefined");}
        if (aauNumber === null) {console.log("+=+=+=+=+=+=+=+=+=+= RatingsPage3 - aauNumber is null");}

        //const response = await axios.get(`https://${baseUrl}/api/ratings`);
        const response = await axios.get(myUrl);
        console.log("+=+=+=+=+=+=+=+=+=+= RatingsPage3 - myUrl: ", myUrl);
        console.log("+=+=+=+=+=+=+=+=+=+= RatingsPage3 - response: ", response);
        const responseData = response.data.message;
        console.log(" RatingsPage3 - responseData: ", responseData);


        if (responseData !== "No data found.") {
          var count = responseData.reduce((p, c) => {
            var event = c.EVENT_EARNED;
            if (!p.hasOwnProperty(event)) {
              p[event] = 0;
            }
            p[event]++;
            return p;
          }, {});

          //let countResult = _.countBy(Ratings, item => item.EVENT);
          console.log("+=+=+=+=+=+=+=+=+=+= RatingsPage3 - count: ", count);
          console.log("+=+=+=+=+=+=+=+=+=+= RatingsPage3 - responseData: ", responseData);
        }


        if (responseData === "No data found.") setIsSkipp(true);
        setRatings(responseData);
        setIsSuccess(true);
        setIsLoading(false);




      } catch (e) {
        setIsSuccess(false);
        setIsLoading(false);
      }
    };

    if (aauNumber !== undefined && aauNumber !== null)
      loadRatings();
  //}, [user]);
  }, [aauNumber]);
  //}, [aauMemberNumber]);


  if (isLoading) return <p>Loading...</p>;
  //if (isSkipp) return <p>No Ratings found... If you think this is in error, please contact support desk.</p>;
  if (isSuccess) {
    console.log(" RatingsPage3 - ratings: ", ratings);
    
    switch (weapon.toUpperCase()) {
      case "E":
        myWeapon = "EPÉE";
        break;
      case "F":
        myWeapon = "FOIL";
        break;
      case "S":
        myWeapon = "SABER";
        break;
      case "H":
        myWeapon = "HEMA";
        break;
      default:
        myWeapon = "EPÉE";
    }

    return (
      <div className="container lh-sm fw-light px-5 mt-4">
            <h1>{info.first} {info.middle} {info.last} (AAU: {aauNumber}) {myWeapon} Ratings</h1>


 <div className="row bg-light m-3">
    <div className="col-1">
      <div className="p-3">
        <p className="mb-0"></p>
      </div>
    </div>
    <div className="col-2 m-1">
      <div className="p-3">
        <p className="mb-0 fw-bold"><a href={BASE_ROUTE+"/E"}>EPÉE</a></p>
      </div>
    </div>
    <div className="col-2 m-1">
      <div className="p-3">
        <p className="mb-0 fw-bold"><a href={BASE_ROUTE+"/S"}>SABER</a></p>
      </div>
    </div>
    <div className="col-2 m-1">
      <div className="p-3">
        <p className="mb-0 fw-bold"><a href={BASE_ROUTE+"/F"}>FOIL</a></p>
      </div>
    </div>
    <div className="col-2 m-1">
      <div className="p-3">
        <p className="mb-0 fw-bold"><a href={BASE_ROUTE+"/H"}>HEMA</a></p>
      </div>
    </div>
    <div className="col-1 m-1">
      <div className="p-3">
        <p className="mb-0 fw-bold"></p>
      </div>
    </div>
</div>


        

        <div className="row">
          <div className="col-12">
            <table>
      <thead>
        <tr>
          <th>AGE</th>
          <th>GEND.</th>
          <th>PLACE</th>
          <th>EARNED</th>
          <th>TOURNAMENT</th>
          <th>LOC.</th>
          <th>DATE</th>
          <th>A</th>
          <th>B</th>
          <th>C</th>
          <th>D</th>
          <th>E</th>
        </tr>
      </thead>
      <tbody>

                {!isSkipp &&

                  ratings.map(({ EVENT_AGE, EVENT_GENDER, EVENT_DATE, PLACE, EARNED, TOURNAMENT_NAME, LOCATION, A, B, C, D, E }) => (
                    <tr>
                      <td>{EVENT_AGE}</td>
                      <td>{EVENT_GENDER}</td>
                      <td>{PLACE}</td>
                      <td>{EARNED}</td>
                      <td>{TOURNAMENT_NAME}</td>
                      <td>{LOCATION}</td>
                      <td>{EVENT_DATE}</td>
                      <td>A{A === "0" ? "-" : A}</td>
                      <td>B{B === "0" ? "-" : B}</td>
                      <td>C{C === "0" ? "-" : C}</td>
                      <td>D{D === "0" ? "-" : D}</td>
                      <td>E{E === "0" ? "-" : E}</td>
                    </tr>
                  ))
                }

                {isSkipp &&
                <tr><td colSpan={11}>NO DATA FOUND</td></tr>
                }

      </tbody>
    </table>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {console.log(" @@ RatingsPage3 - ratings: ", ratings)}
            {console.log(" @@ RatingsPage3 - ratings.length: ", ratings.length)}
            {console.log(" @@ RatingsPage3 - ratings.success: ", ratings.success)}
          </div>
          <a role="button" className="btn btn-primary mt-4" href="/main">
            back
          </a>
        </div>
      </div>
    );
  } else {
    return <p className="text-danger bg-warning">... Could not find Rating/Ranking data. Have you register your AAU# in your profile?<br/>Also verify the AAU#.</p>;
  }
};
//export default EventList;
export default RatingsPage3;