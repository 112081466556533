import React, { useState, useEffect, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import browserInfo from '@smartbear/browser-info'
import { useUser } from '../auth/useUser'
import { useToken } from '../auth/useToken'
import { addPayment } from '../util/addPayment'
import axios from 'axios'
import purify from 'dompurify'
import { useUserData } from '../auth/useUserData'
import { sendMail } from '../util/sendMail'

import './css/EventPay.css'
import EventPayTable from './components/EventPayTable'
import { TournamentPolicy } from './components/TournamentPolicy'
import { isOver } from '../util/getFunctions'

const { nanoid } = require('nanoid')
let errorDisplay = null

let APPLICATION_ID = null
let LOCATION_ID = null

let baseUrl = ''
if (process.env.REACT_APP_NODE_ENV === 'production') {
  baseUrl = 'mongodbprod.fencingleagueofamerica.org:4443'
  APPLICATION_ID = process.env.REACT_APP_SQUARE_APPLICATION_ID_PROD
  LOCATION_ID = process.env.REACT_APP_SQUARE_LOCATION_ID_PROD
} else {
  console.log(' @ EventPay.js - SANDBOX')
  baseUrl = 'mongodb01.fencingleagueofamerica.org:4443'
  APPLICATION_ID = process.env.REACT_APP_SQUARE_APPLICATION_ID
  LOCATION_ID = process.env.REACT_APP_SQUARE_LOCATION_ID
  console.log(' @ EventPay.js - APPLICATION_ID: ', APPLICATION_ID)
  console.log(' @ EventPay.js - LOCATION_ID: ', LOCATION_ID)
}

// https://developer.squareup.com/docs/web-payments/take-card-payment
// https://codesandbox.io/s/square-web-payments-react-nhgxy?file=/src/App.js

browserInfo.detect()

const isSafari = browserInfo.name === 'Safari'

// This function tokenizes a payment method.
// The ‘error’ thrown from this async function denotes a failed tokenization,
// which is due to buyer error (such as an expired card).
export async function tokenizePaymentMethod(paymentMethod) {
  const tokenResult = await paymentMethod.tokenize()
  // A list of token statuses can be found here:
  // https://developer.squareup.com/reference/sdks/web/payments/enums/TokenStatus
  if (tokenResult.status === 'OK') {
    return tokenResult.token
  }
  let errorMessage = `Tokenization failed-status: ${tokenResult.status}`
  if (tokenResult.errors) {
    errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`
  }
  throw new Error(errorMessage)
}

///////////////////////////
///////////////////////////

export const EventPay = (props) => {
  console.log(' @ Event - props: ', props)
  const { eventId } = useParams()

  console.log(' @ Event - eventId: ', eventId)

  const history = useHistory()
  const user = useUser()
  const [token, setToken] = useToken()

  console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@')
  console.log('@@ EventPay - user: ', user)
  const { id: userId, email, isVerified, info, tobi } = user // destructuring assignment

  console.log('@@ EventPay - userId: ', userId)
  const [isLoading, setIsLoading] = useState(true)
  const [isSkipp, setIsSkipp] = useState(false)
  const [isDataLoadSuccess, setIsDataLoadSuccess] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false) //for credit card
  const [events, setEvents] = useState([])
  const [eventsEventFees, setEventsEventFees] = useState([])

  const [loaded, setLoaded] = useState(false)
  const [pageOK, setPageOK] = useState(false)
  const [squarePayments, setSquarePayments] = useState(undefined)
  const [squareCard, setSquareCard] = useState(undefined)
  //const [applePay, setApplePay] = useState(undefined);
  //const [googlePay, setGooglePay] = useState(undefined);
  const [isSubmitting, setSubmitting] = useState(false)
  const [orderConfirmed, setOrderConfirmed] = useState(false) // for "Confirm Order" button
  const [orderConfirmMessageError, setOrderConfirmMessageError] = useState('')
  const [requiredItemMessageError, setRequiredItemMessageError] = useState('')
  const [messageDisplay, setMessageDisplay] = useState('')
  const [validFields, setValidFields] = useState({
    cardNumber: false,
    cvv: false,
    expirationDate: false,
    postalCode: false,
  })
  const [requiredItemList, setRequiredItemList] = useState([])
  const isCardFieldsValid = Object.values(validFields).every((v) => v)
  const [selectedOrders, setSelectedOrders] = useState([])
  const [isReady, setIsReady] = useState(false)
  const [isAccepted, setIsAccepted] = useState(false)
  const [paymentComplete, setPaymentComplete] = useState(false)

  let updatedRequiredList = []
  let newRequiredItemList = []

  const [mailMessage, setMailMessage] = useState({})

  let releaseMode = '' // or "production"

  /////////////////////////////////
  useEffect(() => {
    // load event info / eventFees and set eventsEventFees
    const loadEvents = async () => {
      console.log('Event - useEffect')

      try {
        const response = await axios.get(
          `https://${baseUrl}/api/events/${eventId}`
        )
        console.log(' @@ Event - response: ', response)
        const responseData = response.data.message
        console.log(' @@ Event - responseData: ', responseData)
        console.log(
          ' @@ Event - responseData[0].data.eventFees: ',
          responseData[0].data.eventFees
        )
        if (responseData === 'No data found.') {
          setIsSkipp(true)
        } //else {
        //let updatedRequiredList = {};
        //
        // };

        setEvents(responseData)
        setEventsEventFees(responseData[0].data.eventFees)
        setIsDataLoadSuccess(true)
        setIsLoading(false)
      } catch (e) {
        setIsDataLoadSuccess(false)
        setIsLoading(false)
      }
    }

    loadEvents()
  }, [user])
  ///////////////////////////////

  const [selectedItems, setSelectedItems] = useState([
    //  { id: "Registration", currency: "USD", price: 40, quantity: 1},
  ])

  const [fullOrder, setFullOrder] = useState({})
  //const [fullOrder, setFullOrder] = useState({
  //  total_items:1,
  //  total_charges: 40,
  //  currency: "USD",
  //  line_items: selectedItems
  //});

  let Total = 0
  /*
  let onChange = async (e) => {
    console.log(" @ @ @ EventPay - onChange  e: ", e);
    Total = 0;
    let order = {};
    let UpdatedSelectedItems = [];
    UpdatedSelectedItems = selectedItems;
    if (e.target.checked === true) {
      UpdatedSelectedItems.push({
        id: e.target.id,
        //status: e.target.checked,
        currency: "USD",
        price: e.target.value,
        quantity: "1",
      });
    } else {
      UpdatedSelectedItems = await UpdatedSelectedItems.filter((item) => !(item.id == e.target.id));
    }

    await UpdatedSelectedItems.map((item, id) => {
      Total += parseInt(item.price);
    });

    order = {
      total_items: UpdatedSelectedItems.length,
      total_charges: Total,
      currency: "USD",
      line_items: UpdatedSelectedItems,
    };

    setSelectedItems(UpdatedSelectedItems);
    setFullOrder(order);
  };
  console.log("data", fullOrder);
  */

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [receiptMessage, setReceiptMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [receiptIssued, setReceiptIssued] = useState(false)
  const [msg, setMsg] = useState(null)

  const userData = useUserData(userId)
  console.log('EventPay - userData: ', userData)

  // This useEffect hook automatically hides the
  // success and error messages after 3 seconds when they're shown.
  useEffect(() => {
    if (showSuccessMessage || showErrorMessage) {
      setTimeout(() => {
        setShowSuccessMessage(false)
        //setShowErrorMessage(false); // error messages should not go away....
        //history.go();
      }, 10000)
    }
  }, [showSuccessMessage, showErrorMessage])

  // Add Square script to the page
  useEffect(() => {
    const existingScript = document.getElementById('webPayment')
    if (existingScript) setLoaded(true)
    else {
      const script = document.createElement('script')
      // https://developer.squareup.com/docs/web-payments/take-card-payment
      if (process.env.REACT_APP_NODE_ENV === 'production') {
        //
        script.src = 'https://web.squarecdn.com/v1/square.js'
      } else {
        //
        script.src = 'https://sandbox.web.squarecdn.com/v1/square.js'
      }
      script.id = 'webPayment'
      document.body.appendChild(script)
      script.onload = () => {
        setLoaded(true)
      }
    }
  }, [])

  // Instantiate Square payments and store the object in state
  useEffect(() => {
    if (loaded && !squarePayments) {
      if (!window?.Square) {
        console.error('Square.js failed to load properly')
        return
      }
      setSquarePayments(window.Square?.payments(APPLICATION_ID, LOCATION_ID))
      console.log('EventPay: squarePayments: ' + JSON.stringify(squarePayments))
    }
  }, [loaded, squarePayments])

  const handleConfirmOrderClick = () => {
    if (fullOrder.total_charges > 0) {
      setOrderConfirmMessageError('')
      setOrderConfirmed(true)
      //setLoaded(true);// to populate card attachment
      console.log('#### handleConfirmOrderClick - loaded: ', loaded)
      console.log(
        '#### handleConfirmOrderClick - squarePayments: ',
        squarePayments
      )
      //initializeSquareCard(); // to populate card attachment
    } else {
      setOrderConfirmMessageError('You have not selected any orderable items.')
    }
  }

  // Handle the form submission
  const handlePaymentMethodSubmission = async (paymentMethod) => {
    const isCard = paymentMethod?.element?.id === 'card-container'
    if (isCard && !isCardFieldsValid) return
    if (!isSubmitting) {
      // Disable the submit button as we await tokenization and make a
      // payment request
      if (isCard) setSubmitting(true)
      try {
        const token = await tokenizePaymentMethod(paymentMethod) // Create your own addPayment function to communicate with your API // await addPayment(token) console.log("@@ @@ EventPay - derived TOKEN:", token); // this works kokanish // https://developer.squareup.com/docs/web-payments/take-card-payment
        //const paymentResults = await createPayment(token);
        //    const paymentResults = await createPayment(token);
        let payload = token //kokanish

        const idempotencyKey = payload.idempotencyKey || nanoid()
        console.log(' @@@@@@@@@@@ events state: ', events[0].data)
        const requestBody = {
          idempotencyKey,
          //locationId: payload.locationId,
          locationId: LOCATION_ID,
          //sourceId: payload.sourceId,
          sourceId: token,
          note:
            'FLA ' + events[0].data.eventType + eventId + '-' + info.memberId, // this gets displayed in the "transactions" of the dash
          // and Description
          // referenceId cannot be more than 20 chars!!! - per error message returned
          // ex. 3rd party order ID, etc
          //referenceId: "ref-" + info.memberId + "-tournament001",
          referenceId:
            'ref-' +
            info.memberId +
            '-' +
            events[0].data.eventType +
            '-' +
            eventId,
          // statementDescriptionIdentifier cannot be more than 20 chars!!! - per error message returned
          statementDescriptionIdentifier: 'FLA' + '-' + eventId + ' PMT',
          // While it's tempting to pass this data from the client
          // Doing so allows bad actor to modify these values
          // Instead, leverage Orders to create an order on the server
          // and pass the Order ID to createPayment rather than raw amounts
          // See Orders documentation: https://developer.squareup.com/docs/orders-api/what-it-does
          amountMoney: {
            // the expected amount is in cents, meaning this is $1.00.
            //amount: '100',
            ////////////amount: paymentRequestMock.total.amount,
            //amount: paymentRequestMock.total.amount,
            amount: fullOrder.total_charges,
            // If you are a non-US account, you must change the currency to match the country in which
            // you are accepting the payment.
            //currency: 'USD', // hard coding
            currency: fullOrder.currency,
          },
          buyerEmailAddress: email,
          customerId: info.memberId,
        }

        console.log(
          " @@@ EventPay - requestBody for 'addPayment()': " +
            JSON.stringify(requestBody)
        )
        // if (payload.customerId) {
        //   payment.customerId = payload.customerId;
        // }
        // // VerificationDetails is part of Secure Card Authentication.
        // // This part of the payload is highly recommended (and required for some countries)
        // // for 'unauthenticated' payment methods like Cards.
        // if (payload.verificationToken) {
        //   payment.verificationToken = payload.verificationToken;
        // }

        //////////////////////////////////
        // This is the "square.paymentsApi.createPayment" / addPayment() portion mentioned above
        //////////////////////////////////
        const response = await addPayment(requestBody)
        console.log(
          ' @@ EventPay - response back from addPayment(requestBody) json: ' +
            JSON.stringify(response)
        )
        console.log(' @@ EventPay - deconstrunturing...')
        //const { success, message } = JSON.stringify(response);
        const { success, message } = response
        console.log(' @@ EventPay - returned deconst success: ' + success)
        console.log(
          ' @@ EventPay - returned deconst message json: ' +
            JSON.stringify(message)
        )

        //////////////////////////////////

        if (success) {
          // checking response from addPayment

          const {
            id,
            createdAt,
            amountMoneyAmount,
            amountMoneyCurrency,
            approvedMoneyAmount,
            approvedMoneyCurrency,
            sourceType,
            cardBrand,
            last4,
            locationId,
            orderId,
            referenceId,
            statementDescriptionIdentifier,
            receiptNumber,
            receiptUrl,
          } = message

          console.log(' @@ EventPay - deconst const id: ' + id)
          console.log(' @@ EventPay - deconst const createdAt: ' + createdAt)
          console.log(
            ' @@ EventPay - deconst const amountMoneyAmount: ' +
              amountMoneyAmount
          )
          console.log(
            ' @@ EventPay - deconst const amountMoneyCurrency: ' +
              amountMoneyCurrency
          )
          console.log(
            ' @@ EventPay - deconst const approvedMoneyAmount: ' +
              approvedMoneyAmount
          )
          console.log(
            ' @@ EventPay - deconst const approvedMoneyCurrency: ' +
              approvedMoneyCurrency
          )
          console.log(' @@ EventPay - deconst const sourceType: ' + sourceType)
          console.log(' @@ EventPay - deconst const cardBrand: ' + cardBrand)
          console.log(' @@ EventPay - deconst const last4: ' + last4)
          console.log(' @@ EventPay - deconst const locationId: ' + locationId)
          console.log(' @@ EventPay - deconst const orderId: ' + orderId)
          console.log(
            ' @@ EventPay - deconst const referenceId: ' + referenceId
          )
          console.log(
            ' @@ EventPay - deconst const statementDescriptionIdentifier: ' +
              statementDescriptionIdentifier
          )
          console.log(
            ' @@ EventPay - deconst const receiptNumber: ' + receiptNumber
          )
          console.log(' @@ EventPay - deconst const receiptUrl: ' + receiptUrl)

          // how to convert back RFC 3339 UTC date format:
          // const myDT = Date.parse("2022-01-26T22:11:51.855Z");
          // var date = new Date(myDT); // --> "Wed Jan 26 2022 17:11:51 GMT-0500 (EST)"

          const myDT = createdAt
          let dispDT = new Date(myDT).toLocaleDateString()
          let dispTM = new Date(myDT).toLocaleTimeString()

          const msg = `<div className="card">
          <div className="card-body text-dark">
            <h2>Receipt - ${dispDT}&nbsp;${dispTM}</h2>
            <ul>
            <li>Total Charged Amount: ${approvedMoneyCurrency} ${(
            Math.round(approvedMoneyAmount) / 100
          ).toFixed(2)}</li>
            <li>${sourceType} ${cardBrand}: "${last4}"</li>
            <li>Order#: ${orderId}</li>
            <li>Statement Description: ${statementDescriptionIdentifier}</li>
            <li>Receipt#: ${receiptNumber}</li>
            </ul>
            <p>Receipt Link: <a role="button" className="btn btn-outline-primary" href="${receiptUrl}" target="_blank">#${receiptNumber}</a></p>
            </div>
          </div>`
          setReceiptMessage(msg)

          // process recepts to database!

          const msgToDB = {
            data: {
              userId: userId,
              id: id, // payment id
              createdAt: createdAt,
              amountMoneyAmount: amountMoneyAmount,
              amountMoneyCurrency: amountMoneyCurrency,
              approvedMoneyAmount: approvedMoneyAmount,
              approvedMoneyCurrency: approvedMoneyCurrency,
              sourceType: sourceType,
              cardBrand: cardBrand,
              last4: last4,
              locationId: locationId,
              orderId: orderId,
              referenceId: referenceId,
              statementDescriptionIdentifier: statementDescriptionIdentifier,
              receiptNumber: receiptNumber,
              receiptUrl: receiptUrl,
              fullOrder: fullOrder,
            },
          }
          console.log(' @@ EventPay - msgToDB: ' + JSON.stringify(msgToDB))

          const dbInsResult = await axios.post(
            `https://${baseUrl}/api/insertreceipt`,
            msgToDB
          )

          console.log(
            ' @@ EventPay - dbInsResult: ' + JSON.stringify(dbInsResult.data)
          )

          if (dbInsResult.data !== 'OK') {
            setErrorMessage(
              'transaction successful; however, receipt recording failed. Please report to support.'
            )
          }

          console.log('@@ EventPay - fullOrder: ', fullOrder)
          // sending emails
          let prepTextHDR = null
          let prepText = ''
          prepTextHDR = `Total Items: ${fullOrder.total_items}\n
Currency: ${fullOrder.currency}\n
Total Charged: ${fullOrder.total_charges}\n\n
--Line Items--\n`
          let myLineItems = fullOrder['line_items']
          for (var item in myLineItems) {
            for (var subItem in myLineItems[item]) {
              console.log(subItem + ': ' + myLineItems[item][subItem])
              prepText =
                prepText + subItem + ': ' + myLineItems[item][subItem] + '\n'
            }
            prepText = prepText + '\n' // newline so that each item has a blank line after
          }

          console.log('@@ EventPay - prepText: ', prepText)
          if (process.env.REACT_APP_NODE_ENV !== 'production') {
            releaseMode = 'TEST SERVER: '
          }

          setMailMessage({
            //to: "kaz@okanishi.com",
            to: 'entry@fencingleagueofamerica.org',
            subject:
              releaseMode +
              'New FLA payment - ' +
              info.first +
              ' ' +
              info.middle +
              ' ' +
              info.last +
              ' - ' +
              info.memberId +
              ' for #' +
              eventId,
            text:
              releaseMode +
              `A new payment ------\nName: ${info.first} ${info.middle} ${info.last}\n
eMail: ${email}\n
Order ID: ${orderId}\nReference ID: ${referenceId}\nStatement Description: ${statementDescriptionIdentifier}\n
Receipt#: ${receiptNumber}\nReceipt URL: ${receiptUrl}\n` +
              prepTextHDR +
              prepText +
              '\n',
            //html: "<p></p>",
          })

          setPaymentComplete(true)

          setIsSuccess(true)
          setIsLoading(false)
          setShowSuccessMessage(true)

          //console.log(" @@@@ EventPay - payment:"+payment);
          console.log(' @@@@ EventPay - receiptNumber:' + receiptNumber)
        } // end of "checking response from addPayment"
        else {
          //

          throw 'Processing failed....' + message
        }
      } catch (error) {
        // tokenize failed
        console.error('FAILURE error:', error)
        setMessageDisplay(error)
        setShowErrorMessage(true)
      } finally {
        isCard && setSubmitting(false)
      }
    }
  }

  const handleBackClick = () => {
    //history.goBack();
    history.push('/main')
  }

  // Set each card field validity on various events
  const handleCardEvents = ({ detail }) => {
    if (detail) {
      const { currentState: { isCompletelyValid } = {}, field } = detail
      if (field) {
        setValidFields((prevState) => ({
          ...prevState,
          [field]: isCompletelyValid,
        }))
      }
    }
  }

  // Attach the Square card to our container and setup event listeners
  const attachCard = (card) => {
    console.log('####### attachCard()')
    console.log('####### attachCard() - cardObject', squareCard)
    // We pass in the card object during initialization, but re-use it from
    // state for normal re-renders
    const cardObject = card || squareCard
    if (isVerified) cardObject.attach('#card-container') // won't attach if not verified
    //if (isVerified && orderConfirmed) cardObject.attach("#card-container"); // won't attach if not verified
    // Listeners: https://developer.squareup.com/reference/sdks/web/payments/objects/Card#Card.addEventListener
    cardObject.addEventListener('submit', () =>
      handlePaymentMethodSubmission(cardObject)
    )
    cardObject.addEventListener('focusClassAdded', handleCardEvents)
    cardObject.addEventListener('focusClassRemoved', handleCardEvents)
    cardObject.addEventListener('errorClassAdded', handleCardEvents)
    cardObject.addEventListener('errorClassRemoved', handleCardEvents)
    cardObject.addEventListener('cardBrandChanged', handleCardEvents)
    cardObject.addEventListener('postalCodeChanged', handleCardEvents)
  }

  const initializeSquareCard = async () => {
    console.log('########## initializeSquareCard')
    const card = await squarePayments.card()
    console.log('########## initializeSquareCard', card)
    setSquareCard(card)
    attachCard(card)
  }

  // Handle Square payment methods initialization and re-attachment
  useEffect(() => {
    let isMounted = true // https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    if (squarePayments) {
      if (!squareCard) initializeSquareCard()
      //if (!applePay && isSafari) initializeApplePay();
      //if (!googlePay) initializeGooglePay();
      //else attachGooglePay();
    }
    // Otherwise, we destroy the objects and reset state
    else {
      if (squareCard) {
        squareCard.destroy()
        setSquareCard(undefined)
      }
      //if (applePay) {
      //  applePay.destroy();
      //  setApplePay(undefined);
      //}
      //if (googlePay) {
      //  googlePay.destroy();
      //  setGooglePay(undefined);
      //}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      isMounted = false
    }
  }, [squarePayments])

  useEffect(() => {
    // some email send handling
    console.log('@@ EventPay.pingMail - mailMessage: ', mailMessage)

    const pingMail = async () => {
      console.log('EventPay.pingMail - useEffect')
      const emailData = {
        from: 'supportdesk@fencingleagueofamerica.org',
        to: mailMessage.to,
        //cc: "moi@okanishi.com",
        subject: mailMessage.subject,
        text: mailMessage.text,
        html: mailMessage.html,
      }
      setMailMessage(emailData)

      console.log(' @@@@ EventPay.js - emailData: ', JSON.stringify(emailData))

      try {
        const response = await sendMail(emailData)
        console.log(' @@ EventPay.pingMail - response: ', response)
        console.log(' @@ EventPay.pingMail - response #2: ', response.data)
      } catch (e) {
        console.log(' @@ EventPay.pingMail - sendMail Error ')
      }
    }

    if (mailMessage.to && mailMessage.subject && mailMessage.text) {
      pingMail(mailMessage)
    } else {
      console.log(
        ' @@@@ EventPay.js - pingMail error either to, subject or text is/are missing. mailMessage: ',
        mailMessage
      )
    }
  }, [paymentComplete])

  // Some quick button styles
  let cardButtonStyles = {
    backgroundColor: '#ddd',
    color: 'white',
    padding: 16,
    fontFamily: 'sans-serif',
    fontSize: '1rem',
    marginBottom: 16,
    borderRadius: 8,
    borderWidth: 0,
  }
  if (isCardFieldsValid) {
    cardButtonStyles = {
      ...cardButtonStyles,
      backgroundColor: 'green',
    }
  }

  useEffect(() => {
    let newRequiredItemList = []
    setIsReady(false)
    newRequiredItemList = requiredItemList
    console.log(' @@ +++ requiredItemList: ', requiredItemList)
    console.log(' @@ +++ selectedOrders: ', selectedOrders.length)

    selectedOrders.map((item) => {
      // driver table is the selected items
      console.log(' @@ +++ selectedOrders-map : ', item.original.itemId)
      newRequiredItemList = newRequiredItemList.filter(
        (reqItem) => !(reqItem.itemId === item.original.itemId)
      )
      console.log(' @@ +++ newRequiredItemList : ', newRequiredItemList)
      if (newRequiredItemList.length === 0) {
        setIsReady(true)
      }
    })
  }, [requiredItemList, selectedOrders])

  useEffect(() => {
    // process selectedOrders for FullOrder object build
    //
    console.log(
      ' @ @ @ @ @ @ @ >>>> EventPay - selectedOrders: ',
      selectedOrders
    )
    console.log(
      ' @ @ @ @ @ @ @ >>>> EventPay - selectedOrders.length: ',
      selectedOrders.length
    )
    console.log(
      ' @ @ @ @ @ @ @ >>>> EventPay - requiredItemList: ',
      requiredItemList
    )
    console.log(
      ' @ @ @ @ @ @ @ >>>> EventPay - requiredItemList.length: ',
      requiredItemList.length
    )

    Total = 0 // has been initialized as a global var
    let order = {}
    let UpdatedSelectedItems = []

    ////////////////
    if (
      userId == '620364894ca1b537b56f9243' || //kaz db01
      userId == '622b1a557d008a1ee7338ff7' || //kaz prod
      userId == '62e370d60ca72e0e1b8cdb13' // yumi prod
    ) {
      console.log('EventPay - DBG userId was ', userId, '!!!')
      console.log('EventPay - DBG ordered.original.price: ', selectedOrders)
    }
    //////////////////

    if (selectedOrders.length >= 0) {
      selectedOrders.map((ordered) => {
        console.log(
          'EventPay - selectedOrders - ordered.original: ',
          ordered.original
        )
        //////////////
        if (
          userId == '620364894ca1b537b56f9243' || //kaz db01
          userId == '622b1a557d008a1ee7338ff7' || //kaz prod
          userId == '62e370d60ca72e0e1b8cdb13' //yumi prod
        ) {
          ordered.original.price = 1.0 // right now less than 1.0 may not work
        }
        //////////////
        UpdatedSelectedItems.push({
          id: ordered.original.itemId,
          currency: ordered.original.currency,
          price: ordered.original.price,
          quantity: '1',
        })
        Total += parseInt(ordered.original.price)
      }) // selectedOrders.map()

      console.log(
        'EventPay - UpdatedSelectedItems array: ',
        UpdatedSelectedItems
      )
      // assemble order object with UpdatedSelectedItems array built above
      order = {
        total_items: UpdatedSelectedItems.length,
        total_charges: Total,
        currency: 'USD',
        line_items: UpdatedSelectedItems,
      }
      console.log('EventPay - order: ', order)
      setSelectedItems(UpdatedSelectedItems)
      setFullOrder(order)
    } // if (selectedOrders.length>0)
  }, [selectedOrders])

  // let onChange = async (e) => {
  //  setIsAccepted(e.target.checked);
  // console.log(" @ @ @ EventPay - onChange  e: ", e);
  //    console.log(" @ @ @ EventPay - onChange  e.target.checked: ", e.target.checked);
  //   console.log(" @ @ @ EventPay - onChange  isAccepted: ", isAccepted);
  //  }
  ///////////////////////////////////////

  ///////////////////////////////////////
  console.log('  EventPay - events: ', events)
  console.log('  EventPay - events.length: ', events.length)
  console.log('  EventPay - eventsEventFees: ', eventsEventFees)

  const ParentFunction = (Array) => {
    console.log('Calling this from Parent Function', Array)
    setSelectedOrders(Array)
  }

  const ParentSetRequredItemListFunction = (Array) => {
    console.log(
      'Calling ParentSetRequredItemListFunction from Parent Function',
      Array
    )
    setRequiredItemList(Array)
  }

  //useEffect(() => {
  //  let newRequiredItemList = requiredItemList;
  //  console.log("  @@@@@@ EventPay - newRequiredItemList in useEffect: "+JSON.stringify(newRequiredItemList));
  //}, [newRequiredItemList]);

  function GetHTMLDisplayData(props) {
    console.log('  @@ Event - GetHTMLDisplayData - props.data', props.data)
    if (props.data !== '') {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: purify.sanitize(props.data) }}
        />
      )
    }
  }

  let myVar = events[0]
  //console.log('@YAHOO: events: ', events)
  console.log('@YAHOO: myVar: ', myVar)

  return (
    <div className="container px-5">
      <>
        <div className="container">
          <div className="card text-dark">
            {
              events.map(
                (event, key) => (
                  <>
                    <div key={key} className="card-header">
                      #{event.data.eventId} -{' '}
                      <GetHTMLDisplayData data={event.data.eventTitleHTML} />
                    </div>
                    <div className="card-body">
                      <GetHTMLDisplayData
                        data={event.data.eventDescriptionsHTML1}
                      />
                      <GetHTMLDisplayData
                        data={event.data.eventDescriptionsHTML2}
                      />
                      <GetHTMLDisplayData
                        data={event.data.eventDescriptionsHTML3}
                      />
                      <GetHTMLDisplayData
                        data={event.data.eventDescriptionsHTML4}
                      />
                      <GetHTMLDisplayData
                        data={event.data.eventDescriptionsHTML5}
                      />

                      {isOver(
                        event.data.eventStartDate,
                        event.data.eventDays
                      ) === 0 && <h3>Events &amp; Fees</h3>}
                      {isOver(
                        event.data.eventStartDate,
                        event.data.eventDays
                      ) === 0 &&
                        events.length > 0 &&
                        !isSuccess && (
                          <EventPayTable
                            ParentFunction={ParentFunction}
                            ParentSetRequredItemListFunction={
                              ParentSetRequredItemListFunction
                            }
                            data={eventsEventFees}
                          />
                        )}
                    </div>
                    <div className="card text-dark">
                      {isOver(
                        event.data.eventStartDate,
                        event.data.eventDays
                      ) === 0 && (
                        <div className="card-title px-2 pt-1">
                          Order Summary
                        </div>
                      )}

                      {isOver(
                        event.data.eventStartDate,
                        event.data.eventDays
                      ) === 0 &&
                        fullOrder.line_items && (
                          <div className="card-body">
                            {fullOrder.line_items.map((item, itemKey) => {
                              console.log(
                                ' @@@@ EventPay - updatedRequiredList contents NOW: ',
                                updatedRequiredList
                              )
                              console.log(' @@@@ EventPay - item: ', item)

                              //let newRequiredItemList = requiredItemList;// this should be outside of line_items.map //////////////
                              console.log(
                                '  @@@@@@ EventPay -> newRequiredItemList: ',
                                newRequiredItemList
                              )
                              console.log(
                                '  @@@@@@ EventPay -> item.id: ',
                                item.id
                              )
                              //newRequiredItemList = requiredItemList.filter(reqItem => !(reqItem.itemId === item.id)
                              newRequiredItemList = newRequiredItemList.filter(
                                (reqItem) => !(reqItem.itemId === item.id)
                              )
                              console.log(
                                '  @@@@@@ EventPay -> newRequiredItemList #2 result: ' +
                                  JSON.stringify(newRequiredItemList)
                              )

                              return (
                                <div
                                  className="row px-2 py-0 mx-2"
                                  key={fullOrder.line_items.id}
                                >
                                  <div className="col">{item.id}</div>
                                  <div className="col">
                                    {item.currency} {item.price}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )}

                      <div className="card-footer">
                        {/*newUpdatedRequiredList.map((reqItem, k4) => {
                      return (
                        <div className="row">
                          <div className="col text-danger bg-warning mx-4 my-2 py-1">"<span className="fw-bold">{reqItem.item}</span>" is not selected. It is a required item. Are you sure?</div>
                        </div>
                      );
                    })*/}
                        {isOver(
                          event.data.eventStartDate,
                          event.data.eventDays
                        ) === 0 && (
                          <div className="row">
                            <div className="col fw-bold">Total:</div>
                            <div className="col fw-bold">
                              {' '}
                              {fullOrder.currency} {fullOrder.total_charges}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {isOver(event.data.eventStartDate, event.data.eventDays) ===
                      0 && (
                      <div className="card pt-2">
                        <div className="card-body">
                          <label className="p-2 m-2 bg-warning">
                            I agree &amp; accept FLA Policy, Liability Waiver
                            and Photo/Video Release&nbsp;
                            <input
                              type="checkbox"
                              disabled={orderConfirmed}
                              onChange={() => {
                                setIsAccepted(!isAccepted)
                              }}
                            />
                            &nbsp;
                            <span className="fw-bold text-danger">
                              (Required)
                            </span>
                          </label>

                          <div
                            className="accordion"
                            id="regularAccordionRobots"
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="regularHeadingSecond"
                              >
                                <button
                                  className="accordion-button collapsed text-primary"
                                  style={{ fontSize: '13px' }}
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#regularCollapseSecond"
                                  aria-expanded="false"
                                  aria-controls="regularCollapseSecond"
                                >
                                  FLA Policy, Liability Waiver &amp; Photo/Video
                                  Release
                                </button>
                              </h2>
                              <div
                                id="regularCollapseSecond"
                                className="accordion-collapse collapse"
                                aria-labelledby="regularHeadingSecond"
                                data-bs-parent="#regularAccordionRobots"
                              >
                                <div className="accordion-body">
                                  <TournamentPolicy />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) // events.map(event,key) (....)
              ) // events.map()
            }
          </div>
        </div>

        {orderConfirmMessageError && !fullOrder.total_items && (
          <div className="text-danger bg-warning px-2 mt-3 rounded-3">
            {orderConfirmMessageError}
          </div>
        )}
        {requiredItemMessageError && (
          <div className="text-danger bg-warning px-2 mt-3 rounded-3">
            {requiredItemMessageError}
          </div>
        )}
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-success px-4 py-3 mt-5"
            onClick={() => handleConfirmOrderClick()}
            disabled={orderConfirmed || !isReady || !isAccepted}
          >
            {!orderConfirmed
              ? 'Confirm Order Above'
              : !isSuccess
              ? 'Submit Payment Below'
              : 'Thank You!'}
          </button>
        </div>

        {console.log("fullOrder['line_items']: ", fullOrder['line_items'])}
        {console.log('selectedItems: ', selectedItems)}

        {!isVerified && (
          <div className="text-danger pt-5">
            We cannot process any card payments until you verify your email.
          </div>
        )}
        {isSuccess && (
          <>
            <p>Success!!</p>
            <hr />
          </>
        )}
        {isSuccess && errorMessage != null && <p>{errorMessage}</p>}
        {showSuccessMessage && (
          <div className="success">Successfully processed payment!</div>
        )}
        {showErrorMessage && (
          <div className="error">
            Payment processing failed - {messageDisplay}
          </div>
        )}
        {isSuccess && (
          <div
            id="receipt"
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(receiptMessage),
            }}
          />
        )}

        <div
          style={{
            display: orderConfirmed ? 'flex' : 'none',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#144378',
            opacity: '0.8',
            padding: '15px 20px',
            margin: '20px',
          }}
        >
          {!isSuccess && <div id="card-container"></div>}
          {!isSuccess && isReady && (
            <button
              id="card-button"
              type="button"
              style={cardButtonStyles}
              //disabled={!isCardFieldsValid || isSubmitting|| !isVerified}
              disabled={
                !isCardFieldsValid ||
                isSubmitting ||
                !isVerified ||
                !orderConfirmed ||
                !isReady ||
                !isAccepted
              }
              onClick={() => handlePaymentMethodSubmission(squareCard)}
            >
              Pay{' '}
              {
                //paymentRequestMock.total.amount
                fullOrder.currency
              }{' '}
              {fullOrder.total_charges}
            </button>
          )}
        </div>
        {/* </form> */}
        <div id="payment-status-container"></div>
      </>
      <button
        className="btn btn-sm bg-light ms-5 my-5"
        onClick={handleBackClick}
      >
        {isSuccess ? 'Back' : 'Cancel/Back'}
      </button>
    </div>
  ) // events.map()
} // EventPay
