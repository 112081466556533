// based on u_seProfileData
// this is a funtion - do not need to be on Route.js as being called in pages.
import { useEffect, useState } from 'react'
import axios from 'axios'

let baseUrl = ''
if (process.env.REACT_APP_NODE_ENV === 'production') {
    baseUrl = 'mongodbprod.fencingleagueofamerica.org:4443'
} else {
    baseUrl = 'mongodb01.fencingleagueofamerica.org:4443'
}

export const useEventData = (eventId = '00005', userId) => {
    ////////////// default for now //////////
    const url = `https://${baseUrl}/api/events/` + eventId
    console.log('   auth/useEventData - url: ' + url)
    console.log('   auth/useEventData - eventId received: ' + eventId)
    console.log('   auth/useEventData - userId received: ' + userId)

    const fetchData = async () => {
        const result = await axios(url);
        console.log('   auth/useEventData - result: ', result.data);
        //setEventData(result.data);
        return result.data
    }

    fetchData();




    /*
    const [eventData, setEventData] = useState(() => {
        //
        axios
            .get(url)
            .then((res) => {
                //setProfile(res.data.profile);
                //const hahaha = {"userId": 123, "dob": "12/17/1969", "bufbuf": "AAABBCC",};
                //setProfile(hahaha);
                console.timeStamp('----')
                console.log(
                    '   auth/useEventData - 1 res: ' + JSON.stringify(res)
                ) // change back to res.data
                console.log(
                    '   auth/useEventData - 2 Status: ' +
                        JSON.stringify(res.status)
                )
                console.log(
                    '   auth/useEventData - 3 res.data: ' +
                        JSON.stringify(res.data)
                ) // change back to res.data
                console.log(
                    '   auth/useEventData - 4 resdata.message: ' +
                        JSON.stringify(res.data.message)
                )
                //            console.log("   auth/useProfile - 5 profile: "+JSON.stringify(profile));
                console.log(
                    '   >>>> auth/useEventData.js - 7 setting setEventData.... : ' +
                        JSON.stringify(res.data.message) +
                        ' <<<<<<'
                )
                //setProfile(res.data.profile);
                setEventData(res.data.message)
                console.log(
                    '   >>>> auth/useEventData.js - 8 AFTER setting setEventData.... : ' +
                        eventData +
                        ' <<<<<<'
                )
                return eventData
            })
            .catch((error) => {
                if (error.response) {
                    console.log(
                        '   auth/useEventData - !!!!!!!!!!! fetch err !!!!!!!!!!!: ' +
                            JSON.stringify(error)
                    )
                    //console.log(error.response.headers);
                }
                //console.error('There was an error!', error);
            })
    }) // setEventData

    //console.log('   auth/useEventData - eventData right before return!!!: ' + eventData)
    //return [eventData, setEventData]






    */




} // useEventData
