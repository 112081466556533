//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Router, Route, Switch } from 'react-router-dom'
import { IndexPage } from './pages/IndexPage'
import { EmailVerificationLandingPage } from './pages/EmailVerificationLandingPage'
import { ForgotPasswordPage } from './pages/ForgotPasswordPage'
import { LogInPage } from './pages/LogInPage'
import { LogOutPage } from './pages/LogOutPage'
import { SignUpPage } from './pages/SignUpPage'
import { UserProfilePage } from './pages/UserProfilePage'
import { PasswordResetLandingPage } from './pages/PasswordResetLandingPage'
import { PleaseVerifyEmailPage } from './pages/PleaseVerifyEmailPage'
import { MainPage } from './pages/MainPage'
import { PrivateRoute } from './auth/PrivateRoute'
import NotFoundPage from './pages/NotFoundPage'
//import history from './history'; // this won't work some how
import { createBrowserHistory } from 'history'

import { CurrentUserLoader } from './auth/CurrentUserLoader'
import { UserInfo } from './pages/UserInfo'

import { TestPage } from './pages/TestPage'
import { CardPaymentPage } from './pages/CardPaymentPage'

import { ProfileInfoForm } from './HOCs/ProfileInfoForm'
import { useUser } from './auth/useUser'
import { TestTournament01 } from './pages/TestTournament01'
import { TermsPage } from './pages/TermsPage'
import { TermsPage2 } from './pages/TermsPage2'
import { AboutPage } from './pages/About'
import { PaymentActivities } from './pages/PaymentActivities'
import { Receipt } from './pages/Receipt'
import EventList from './pages/EventList'
import { Event } from './pages/Event'
import { EventPay } from './pages/EventPay'
import { PaidList } from './pages/PaidList'
import RatingsPage from './pages/RatingsPage'
import FullRatingsPage from './pages/FullRatingsPage'
import { EditTournamentPage } from './pages/EditTournamentPage'
import RatingsPage2 from './pages/RatingsPage2'
import RatingsPage3 from './pages/RatingsPage3'
import RatingsPage4 from './pages/RatingsPage4'
import MyRatingsPage from './pages/MyRatingsPage'

// you shouldn't create the history object in a component because
// you will be creating a new one every render. That should be hoisted outside.
//const customHistory = createBrowserHistory({forceRefresh: true});
const history = createBrowserHistory()

export const Routes = () => {
  const user = useUser()
  console.log('@@ Routes - user: ' + JSON.stringify(user))

  // https://reactforyou.com/history-push-is-only-changing-the-url-not-rendering-component-react-fix/#:~:text=If%20you%20configure%20the%20router%20component%20like%20the,from%20react-router-dom%20instead%20of%20importing%20BrowserRouter%20as%20Router.
  console.log('Routes - user: ' + JSON.stringify(user))
  //console.log("Routes - user.id: "+JSON.stringify(user.id));
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute path="/" exact>
          <IndexPage />
        </PrivateRoute>
        <PrivateRoute path="/userinfo">
          <CurrentUserLoader>
            <UserInfo />
          </CurrentUserLoader>
        </PrivateRoute>
        <PrivateRoute path="/main">
          <MainPage />
        </PrivateRoute>
        <PrivateRoute path="/profile">
          <UserProfilePage yayayaya={'aaa'} />
        </PrivateRoute>
        <PrivateRoute path="/profileinfoform">
          {user && <ProfileInfoForm userId={user.id} />}
        </PrivateRoute>
        <Route path="/test">
          <IndexPage />
        </Route>
        <Route path="/verify-email/:verificationString">
          <EmailVerificationLandingPage />
        </Route>
        <Route path="/forgot-password">
          <ForgotPasswordPage />
        </Route>
        <Route path="/login">
          <LogInPage />
        </Route>
        <Route path="/logout">
          <LogOutPage />
        </Route>
        <Route path="/reset-password/:passwordResetCode">
          <PasswordResetLandingPage />
        </Route>
        <Route path="/please-verify">
          <PleaseVerifyEmailPage />
        </Route>
        <Route path="/signup">
          <SignUpPage />
        </Route>
        <PrivateRoute path="/cardpayment" exact>
          {user && <CardPaymentPage userId={user.id} />}
        </PrivateRoute>
        <PrivateRoute path="/testtournament01" exact>
          {user && <TestTournament01 userId={user.id} />}
        </PrivateRoute>
        <PrivateRoute path="/paymentactivities" exact>
          {user && <PaymentActivities userId={user.id} />}
        </PrivateRoute>
        <PrivateRoute path="/receipt/:receipt">
          {user && <Receipt userId={user.id} />}
        </PrivateRoute>
        <PrivateRoute path="/events" exact>
          {user && <EventList />}
        </PrivateRoute>
        <PrivateRoute path="/events/:eventId" exact>
          {user && <Event userId={user.id} />}
        </PrivateRoute>
        <PrivateRoute path="/eventpayment/:eventId" exact>
          {user && <EventPay userId={user.id} />}
        </PrivateRoute>
        <PrivateRoute path="/ratings" exact>
          {user && <RatingsPage userId={user.id} />}
        </PrivateRoute>
        <PrivateRoute path="/ratings4/:weapon?">
          {user && <RatingsPage4 userId={user.id} abc="aabbcc" />}
        </PrivateRoute>
        <PrivateRoute path="/myratings/:weapon?">
          {user && <MyRatingsPage userId={user.id} />}
        </PrivateRoute>
        <PrivateRoute path="/fullratings" exact>
          {user && <FullRatingsPage userId={user.id} />}
        </PrivateRoute>
        <PrivateRoute path="/paidlist/:eventId" exact>
          {user && <PaidList />}
        </PrivateRoute>
        <PrivateRoute path="/edittournament/:eventId" exact>
          {user && <EditTournamentPage />}
        </PrivateRoute>
        <Route path="/terms">
          <TermsPage2 />
        </Route>
        <Route path="/testpage">
          <TestPage />
        </Route>
        <Route path="/about">
          <AboutPage />
        </Route>
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  )
}
