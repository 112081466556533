// The EditTournamentPage
//   We're not using "form" in this functional module.
// the "events" collection would hold following fields:
// eventId
// eventType
// eventOrganizerId
// eventTitle
// eventTitleHTML
// eventStartDate
// eventDays
// isRegistrationRequired
// isPaymentRequiredToRegister
// registrationStartDate
// registrationEndDate
// registrationWithdrawalRefundEndDate
// eventDescriptionsHTML1
// eventDescriptionsHTML2
// eventDescriptionsHTML3
// eventDescriptionsHTML4
// eventDescriptionsHTML5
// registrationDetails
// paymentDetails
// eventFees [item, itemId, currency, price, required, gender, startDateTime]
//
// the "eventFees" may be dealt in another page for simplifying the programming
//
//

import React from 'react'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { useToken } from '../auth/useToken'
import { useUser } from '../auth/useUser'
import { useEventData } from '../auth/useEventData'

import './css/UserProfilePage.css'

let baseUrl = ''
if (process.env.REACT_APP_NODE_ENV === 'production') {
    baseUrl = 'mongodbprod.fencingleagueofamerica.org:4443'
} else {
    baseUrl = 'mongodb01.fencingleagueofamerica.org:4443'
}

export const EditTournamentPage = (props) => {
    //const {profileData} = useParams(); // don't forget to import useParams

    console.log('@@ EditTournamentPage - PROPS : ' + JSON.stringify(props))

    const user = useUser()
  const [events, setEvents] = useState([]);
    const [token, setToken] = useToken()

    console.log('@@ EditTournamentPage - type of user: ' + typeof user)
    console.log('@@ EditTournamentPage - user: ' + JSON.stringify(user))

    const { id, email, isVerified, info, tobi } = user // destructuring assignment

    console.log(
        '@@ >> destructured id: ' +
            id +
            ' email: ' +
            email +
            ' isVerified: ' +
            isVerified
    )

    // We'll use the history to navigate the user
    // programmatically. after push, we'd need go() for forced refresh.
    const history = useHistory()



  useEffect(() => {
    // load event info / eventFees and set eventsEventFees
    const loadEvents = async () => {
      console.log("    @@ EditTournamentPage *useEffect* - useEffect!!!!");

      try {
        //const response = await axios.get(`https://${baseUrl}/api/events/${eventId}`);
        const response = await axios.get(`https://${baseUrl}/api/events/00005`);
        console.log("   @@ EditTournamentPage *useEffect* - response.data: ", response.data);
        const responseData = response.data;
        console.log("   @@ EditTournamentPage *useEffect* - responseData: ", responseData);
        //console.log("   @@ EditTournamentPage ** - responseData[0].data.eventFees: ", responseData[0].data.eventFees);
        if (responseData.message === "No data found.") {
            console.log("   @@ EditTournamentPage *useEffect* - responseData: No data found. ", responseData);
          //setIsSkipp(true);
        } else {
        //let updatedRequiredList = {};
        //
            console.log("   @@ EditTournamentPage *useEffect* - setting setevents ");
            setEvents(responseData[0].data);
         };

      } catch (e) {
        //
      }
    };

    loadEvents();
  //}, [user]);
  }, [user]);



    //const { errorMessage } = eventData
    //if (errorMessage === 'bad request?') {
    //    console.log(' @@@@@@ >> EditTournamentPage - SERIOUS ISSUE detected!')
    //    // issues with id in DB. either user no longer exists or other serious issue...
    //    history.push('/logout')
    //}

    //console.log("  @@@@@ EditTournamentPage - info.bio: "+info.bio)
    //console.log('  @@@@@ EditTournamentPage - info: ' + info)
    //console.log('  @@@@@ EditTournamentPage - eventData.bio: ' + eventData.bio)

    // These states are bound to the values of the text inputs
    // on the page (see JSX below).
    //    const [favoriteFood, setFavoriteFood] = useState(info.favoriteFood || '');
    //    const [hairColor, setHairColor] = useState(info.hairColor || '');
    //    const [bio, setBio] = useState(info.bio || '');

    console.log(' .. @@EditTournamentPage.js - events now: ', events);

    //if (user) {
    //if (eventData) {
    if (user) {
        // would use of "user" make sense? it would have been already verified by route
        // thus if we can check eventData....
        if (events === null) {
            return (<div>Loading?</div>)
        } else {
            console.log(' . @@EditTournamentPage.js - events now: ', events);
        ///////////////////
        //if (first === undefined) return <p>loading....</p>
        // And here we have the JSX for our component. It's pretty straightforward
        return (
            <div className="container-fluid lh-sm fw-light px-5 mt-4">
                <h1>Event Edit Page</h1>
                <p className="text-warning p-2">
                    Incomplete/inaccurate information on your profile may lead
                    to delays, disqualifications and/or unnacceptance of your
                    event registrations and results.
                </p>
                <div className="row">
                    <div className="col-12">
                        <>
                            <h2>
                                aaaaaaaa
                            </h2>
                            <hr />
                            <hr />
                        </>
                    </div>
                </div>
            </div>
        )
        } // else
    } // if (myProfile)
    else {
        return <p>loading....</p>
    }
} // EditTournamentPage
