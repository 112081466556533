import React from 'react'
import { useState, useEffect, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useUser } from '../auth/useUser'
import { useUserData } from '../auth/useUserData'

import './css/EventList.css'

let baseUrl = ''
if (process.env.REACT_APP_NODE_ENV === 'production') {
  baseUrl = 'mongodbprod.fencingleagueofamerica.org:4443'
} else {
  baseUrl = 'mongodb01.fencingleagueofamerica.org:4443'
}

// https://stackoverflow.com/questions/55521912/multiple-fetch-data-axios-with-react-hooks

// the above is for multiple fetching!!!!!!!!!!

export const RatingsPage4 = (props) => {
  const user = useUser()

  // https://stackoverflow.com/questions/65678961/react-route-params-with-default-value
  const { weapon = 'E' } = useParams() // default value to be 'E' for now
  console.log('RatingsPage4 - user: ', user)
  console.log(' @ RatingsPage4 - props: ', props)
  console.log(' @ RatingsPage4 - weapon: ', weapon)

  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [ratings, setRatings] = useState([])
  const [isSkipp, setIsSkipp] = useState(false)
  //const [aauMemberNumber, setAauMemberNumber] = useState('');

  const BASE_ROUTE = '/ratings4'
  var myWeapon = ''

  const { id, email, isVerified, info, tobi } = user // destructuring assignment

  console.log(
    '@@ >> destructured id: ' +
      id +
      ' email: ' +
      email +
      ' isVerified: ' +
      isVerified
  )
  console.log(
    '@@ >> destructured info: ' +
      JSON.stringify(info) +
      ' tobi: ' +
      tobi +
      ' << @@'
  )

  // We'll use the history to navigate the user
  // programmatically. after push, we'd need go() for forced refresh.
  const history = useHistory()
  const userData = useUserData(id)
  console.log(
    ' @@@@@@ >> RatingsPage4 - userData Loaded: ' +
      JSON.stringify(userData) +
      ' << @@@@@@ '
  )
  const { aauNumber } = userData
  console.log(
    ' @@@@@@ >> RatingsPage4 - aauNumber DECONST: ' + aauNumber + '!!!'
  )
  //setAauMemberNumber(aauNumber);

  const { errorMessage } = userData
  if (errorMessage === 'bad request?') {
    console.log(' @@@@@@ >> RatingsPage4 - SERIOUS ISSUE detected!')
    // issues with id in DB. either user no longer exists or other serious issue...
    history.push('/logout')
  }

  //console.log("  @@@@@ UserProfilePage - info.bio: "+info.bio)

  useEffect(() => {
    const loadRatings = async () => {
      console.log('RatingsPage4 - useEffect')
      try {
        // path: '/api/ratingsweapon/:AAUNum/:weapon?',
        //const myUrl = `https://${baseUrl}/api/ratings/`+aauNumber;
        const myUrl =
          `https://${baseUrl}/api/myratingsbyweapon/` +
          aauNumber +
          `/` +
          weapon.toUpperCase()
        if (aauNumber === undefined) {
          console.log(
            '+=+=+=+=+=+=+=+=+=+= RatingsPage4 - aauNumber is undefined'
          )
        }
        if (aauNumber === null) {
          console.log('+=+=+=+=+=+=+=+=+=+= RatingsPage4 - aauNumber is null')
        }

        //const response = await axios.get(`https://${baseUrl}/api/ratings`);
        const response = await axios.get(myUrl)
        console.log('+=+=+=+=+=+=+=+=+=+==== RatingsPage4 - myUrl: ', myUrl)
        console.log(
          '+=+=+=+=+=+=+=+=+=+==== RatingsPage4 - response: ',
          response
        )
        //const responseData = response.data.message;
        const responseData = response.data
        console.log(' RatingsPage4 - responseData: ', responseData)

        if (responseData !== 'No data found.') {
          var count = responseData.reduce((p, c) => {
            var event = c.EVENT_EARNED
            if (!p.hasOwnProperty(event)) {
              p[event] = 0
            }
            p[event]++
            return p
          }, {})

          //let countResult = _.countBy(Ratings, item => item.EVENT);
          console.log('+=+=+=+=+=+=+=+=+=+= RatingsPage4 - count: ', count)
          console.log(
            '+=+=+=+=+=+=+=+=+=+= RatingsPage4 - responseData: ',
            responseData
          )
        }

        //if (responseData === 'No data found.') setIsSkipp(true)
        if (responseData.length === 0) setIsSkipp(true)
        setRatings(responseData)
        setIsSuccess(true)
        setIsLoading(false)
      } catch (e) {
        setIsSuccess(false)
        setIsLoading(false)
      }
    }

    if (aauNumber !== undefined && aauNumber !== null) loadRatings()
    //}, [user]);
  }, [aauNumber])
  //}, [aauMemberNumber]);

  if (isLoading) return <p>Loading...</p>
  //if (isSkipp) return <p>No Ratings found... If you think this is in error, please contact support desk.</p>;
  if (isSuccess) {
    console.log(' RatingsPage4 - ratings: ', ratings)

    switch (weapon.toUpperCase()) {
      case 'E':
        myWeapon = 'EPÉE'
        break
      case 'F':
        myWeapon = 'FOIL'
        break
      case 'S':
        myWeapon = 'SABER'
        break
      case 'H':
        myWeapon = 'HEMA'
        break
      default:
        myWeapon = 'EPÉE'
    }
    function ShowAge(props) {
      switch (props.toUpperCase()) {
        case 'Y8':
          return 'Y-8'
        case 'Y10':
          return 'Y-10'
        case 'Y14':
          return 'Y-14'
        case 'CDT':
          return 'CADET'
        case 'JNR':
          return 'JUNIOR'
        case 'SNR':
          return 'SENIOR'
        case 'VET':
          return 'VET'
        default:
          console.log('>> ShowAge props: ', props)
          return props
      }
    }
    function ShowDigest(A, B, C, D, E) {
      console.log('>> ShowDigest props: ', A, B, C, D, E)
      let digest = ''
      //{A === '0' ? '-' : A}
      if (A !== '0') digest = 'A' + A
      if (B !== '0') digest = digest + '  B' + B
      if (C !== '0') digest = digest + '  C' + C
      if (D !== '0') digest = digest + '  D' + D
      if (E !== '0') digest = digest + '  E' + E
      if (digest === '') digest = '-'
      return digest
    }
    console.log(' @ RatingsPage4 - array counts: ', ratings.length)
    return (
      <div className="container lh-sm fw-light px-5 mt-4">
        <h1>
          {info.first} {info.middle} {info.last} (AAU: {aauNumber}) {myWeapon}{' '}
          Ratings
        </h1>
        <div className="row bg-light m-3">
          <div className="col-1">
            <div className="p-3">
              <p className="mb-0"></p>
            </div>
          </div>
          <div className="col-2 m-1">
            <div className="p-3">
              <p className="mb-0 fw-bold">
                <a href={BASE_ROUTE + '/E'}>EPÉE</a>
              </p>
            </div>
          </div>
          <div className="col-2 m-1">
            <div className="p-3">
              <p className="mb-0 fw-bold">
                <a href={BASE_ROUTE + '/S'}>SABER</a>
              </p>
            </div>
          </div>
          <div className="col-2 m-1">
            <div className="p-3">
              <p className="mb-0 fw-bold">
                <a href={BASE_ROUTE + '/F'}>FOIL</a>
              </p>
            </div>
          </div>
          <div className="col-2 m-1">
            <div className="p-3">
              <p className="mb-0 fw-bold">
                <a href={BASE_ROUTE + '/H'}>HEMA</a>
              </p>
            </div>
          </div>
          <div className="col-1 m-1">
            <div className="p-3">
              <p className="mb-0 fw-bold"></p>
            </div>
          </div>
        </div>

        <div>
          <table>
            <thead>
              <tr>
                {!isSkipp &&
                  ratings.map(({ EVENT_AGE }) => (
                    <th colSpan={5} className="text-center">
                      {ShowAge(EVENT_AGE)}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {!isSkipp &&
                  ratings.map(({ A, B, C, D, E }) => (
                    <>
                      <td>A{A === '0' ? '-' : A}</td>
                      <td>B{B === '0' ? '-' : B}</td>
                      <td>C{C === '0' ? '-' : C}</td>
                      <td>D{D === '0' ? '-' : D}</td>
                      <td>E{E === '0' ? '-' : E}</td>
                    </>
                  ))}
              </tr>
              <tr>
                {!isSkipp &&
                  ratings.map(({ EVENT_AGE, A, B, C, D, E }) => (
                    <>
                      <td colSpan={5} className="text-center px-3 mx-3">
                        {ShowAge(EVENT_AGE)}&nbsp;
                        {myWeapon}
                        <br />
                        Rankings
                        <br />
                        <h5 className="fw-bolder text-dark">
                          {ShowDigest(A, B, C, D, E)}
                        </h5>
                      </td>
                    </>
                  ))}
              </tr>
            </tbody>
          </table>
          {isSkipp && (
            <table>
              <thead>
                <tr>
                  <th colSpan="12">NO DATA FOUND</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="12">NO TOURNAMENT DATA FOR THIS WEAPON</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            {console.log(' @@ RatingsPage4 - ratings: ', ratings)}
            {console.log(' @@ RatingsPage4 - ratings.length: ', ratings.length)}
            {console.log(
              ' @@ RatingsPage4 - ratings.success: ',
              ratings.success
            )}
          </div>
          <a role="button" className="btn btn-primary mt-4" href="/main">
            back
          </a>
        </div>
      </div>
    )
  } else {
    return (
      <p className="text-danger bg-warning">
        ... Could not find Rating/Ranking data. Have you register your AAU# in
        your profile?
        <br />
        Also verify the AAU#. {aauNumber}: {isSuccess}
      </p>
    )
  }
}
//export default EventList;
export default RatingsPage4
