// RatingsListTable.js
// https://blog.logrocket.com/complete-guide-building-smart-data-table-react/

import React, { useState } from 'react'
import { useTable, useFilters, useSortBy } from 'react-table'

export default function RatingsListTable({ columns, data }) {
  console.log(' @@@@ RatingsListTable received data : ', data)
  console.log(' @@@@ RatingsListTable received columns : ', columns)
  const [filterInput, setFilterInput] = useState('')
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ['data.BORN'], // https://github.com/TanStack/react-table/issues/1804
      },
    },
    useFilters,
    useSortBy
  )

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined
    setFilter('show.name', value)
    setFilterInput(value)
  }

  // Render the UI for your table
  return (
    <>
      {/*
      <input
        value={filterInput}
        onChange={handleFilterChange}
        placeholder={"Search name"}
      />
      */}
      <table {...getTableProps()} style={{ width: '100%' }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'sort-desc'
                        : 'sort-asc'
                      : ''
                  }
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
