import { withEditableProfile } from './withEditableProfile'
import { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useUser } from '../auth/useUser'

import Select from 'react-select'
import countryList from 'react-select-country-list'

import {
    CountryDropdown,
    RegionDropdown,
    CountryRegionData,
} from 'react-country-region-selector'

import validator from 'validator'

let userId = null

export const ProfileInfoForm = withEditableProfile(
    ({
        profile,
        successMessage,
        errorMessage,
        onChangeProfile,
        onSaveProfile,
        onResetProfile,
        onCancelClicked,
    }) => {
        const [dobError, setDobError] = useState('')
        const [phoneError, setPhoneError] = useState('')
        const [contactNumberError, setContactNumberError] = useState('')

        // https://github.com/validatorjs/validator.js

        // react-country-region-selector
        // https://github.com/country-regions/react-country-region-selector
        // https://codesandbox.io/examples/package/react-country-region-selector
        // https://codesandbox.io/s/ii3hr?file=/src/App.js:780-1001
        const [cCountry, setCCountry] = useState('')
        const [region, setRegion] = useState('')

        const selectCountry = (val) => {
            console.log('selectCountry - val: ' + val)
            setCCountry(val)
            //onChangeProfile( {country: cCountry} );
            onChangeProfile({ country: val })
            console.log('selectCountry - country: ' + country)
        }

        const selectRegion = (val) => {
            console.log('selectRegion - val: ' + val)
            setRegion(val)
            //onChangeProfile( {state: region} );
            onChangeProfile({ state: val })
            console.log('selectRegion - state: ' + state)
        }

        //let { dob, phone, streetAddress1, streetAddress2, city, state, postalCode, countrycontactName, contactKind, contactNumber, domimantHand, school, gender } = profile.profile || {}; // why profile.profile?
        let {
            dob,
            phone,
            streetAddress1,
            streetAddress2,
            city,
            state,
            postalCode,
            country,
            contactName,
            contactKind,
            contactNumber,
            domimantHand,
            school,
            gender,
        } = profile || {} // why profile.profile?
        console.log(' @ ProfileInfoForm - profile: ' + JSON.stringify(profile))
        console.log(
            ' @ ProfileInfoForm - successMessage: ' +
                JSON.stringify(successMessage)
        )
        console.log(
            ' @ ProfileInfoForm - errorMessage: ' + JSON.stringify(errorMessage)
        )
        console.log(
            ' @ ProfileInfoForm - onChangeProfile: ' +
                JSON.stringify(onChangeProfile)
        )
        console.log(
            ' @ ProfileInfoForm - onSaveProfile: ' +
                JSON.stringify(onSaveProfile)
        )
        console.log(
            ' @ ProfileInfoForm - onResetProfile: ' +
                JSON.stringify(onResetProfile)
        )
        console.log(
            ' @ ProfileInfoForm - onCancelClicked: ' +
                JSON.stringify(onCancelClicked)
        )

        console.log(' @ ProfileInfoForm - dob: ' + dob)

        if (!dob) {
            dob = ''
        }
        if (!phone) {
            phone = ''
        }
        if (!streetAddress1) {
            streetAddress1 = ''
        }
        if (!streetAddress2) {
            streetAddress2 = ''
        }
        if (!city) {
            city = ''
        }
        if (!state) {
            state = ''
        }
        if (!postalCode) {
            postalCode = ''
        }
        if (!country) {
            country = ''
        }
        if (!phone) phone = ''
        if (!streetAddress1) streetAddress1 = ''
        if (!streetAddress2) streetAddress2 = ''
        if (!city) city = ''
        if (!state) state = ''
        if (!postalCode) postalCode = ''
        if (!country) country = ''
        if (!contactName) {
            contactName = ''
        }
        if (!contactKind) {
            contactKind = ''
        }
        if (!contactNumber) {
            contactNumber = ''
        }
        if (!domimantHand) {
            domimantHand = ''
        }
        if (!school) {
            school = ''
        }
        if (!gender) {
            gender = ''
        }

        const user = useUser()
        const history = useHistory()

        const { isVerified } = user // destructuring assignment

        useEffect(() => {
            if (errorMessage || successMessage) {
                setTimeout(() => {
                    errorMessage = null
                    successMessage = null
                    //history.go();
                }, 10000)
            }
        }, [errorMessage, successMessage])

        // Technically we don't need the userId from parents as withEditableProfile would have the
        // userId and retrieve/update databases with it.

        //console.log("@ ProfileInfoForm - props: "+props);
        //console.log("@ ProfileInfoForm - userId: "+userId);
        return profile ? (
            <>
                <h2>
                    #{user.info.memberId} {user.info.first} {user.info.last}{' '}
                    Personal Profile
                </h2>

                {!isVerified && (
                    <div className="text-danger bg-warning p-2 m-1">
                        You won't be able to make any changes until you verify
                        your email
                    </div>
                )}
                {successMessage && (
                    <div className="success text-primary bg-secondary">
                        Successfully saved profile data!
                    </div>
                )}
                {errorMessage && (
                    <div className="text-danger">
                        Uh oh... something went wrong and we couldn't save
                        changes. {errorMessage}
                    </div>
                )}

                <label>
                    Date of Birth:{' '}
                    <input
                        type="date"
                        value={dob}
                        onChange={(e) => {
                            onChangeProfile({ dob: e.target.value })
                        }}
                        placeholder="MM/DD/YYYY"
                        maxLength="10"
                    />
                    <span className="text-danger">{dobError}</span>
                </label>
                <label>
                    Gender:{' '}
                    <select
                        name="gender"
                        value={gender}
                        onChange={(e) =>
                            onChangeProfile({ gender: e.target.value })
                        }
                    >
                        <option value="">Choose...</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </label>
                <label>
                    Dominant Hand:{' '}
                    <select
                        name="dominantHand"
                        value={domimantHand}
                        onChange={(e) =>
                            onChangeProfile({ domimantHand: e.target.value })
                        }
                    >
                        <option value="">Choose...</option>
                        <option value="right">Right</option>
                        <option value="left">Left</option>
                    </select>
                </label>
                <label>
                    Phone:{' '}
                    <input
                        value={phone}
                        onChange={(e) => {
                            onChangeProfile({ phone: e.target.value })
                        }}
                        placeholder="000-000-0000"
                        maxLength="14"
                    />
                    <span className="text-danger">{phoneError}</span>
                </label>

                <div>
                    <label>
                        Country:
                        <CountryDropdown
                            value={country}
                            onChange={(val) => selectCountry(val)}
                            valueType="short"
                        />
                    </label>{' '}
                    <label>
                        State/Region:{' '}
                        <RegionDropdown
                            country={country}
                            value={state}
                            onChange={(val) => selectRegion(val)}
                            countryValueType="short"
                        />
                    </label>
                    <label>
                        Street Address 1:{' '}
                        <input
                            value={streetAddress1}
                            onChange={(e) =>
                                onChangeProfile({
                                    streetAddress1: e.target.value,
                                })
                            }
                        />
                    </label>
                    <label>
                        Street Address 2:{' '}
                        <input
                            value={streetAddress2}
                            onChange={(e) =>
                                onChangeProfile({
                                    streetAddress2: e.target.value,
                                })
                            }
                        />
                    </label>
                </div>
                <style display="none">
                    <label>
                        <input
                            value={state}
                            onChange={(e) =>
                                onChangeProfile({ state: e.target.value })
                            }
                            disabled
                        />
                    </label>
                    <label>
                        <input
                            value={country}
                            onChange={(e) =>
                                onChangeProfile({ country: e.target.value })
                            }
                            disabled
                        />
                    </label>
                </style>
                <label>
                    City:{' '}
                    <input
                        value={city}
                        onChange={(e) =>
                            onChangeProfile({ city: e.target.value })
                        }
                    />
                </label>
                <label>
                    Postal Code:{' '}
                    <input
                        value={postalCode}
                        onChange={(e) =>
                            onChangeProfile({ postalCode: e.target.value })
                        }
                        maxLength="10"
                    />
                </label>

                <label>
                    School:{' '}
                    <input
                        value={school}
                        onChange={(e) =>
                            onChangeProfile({ school: e.target.value })
                        }
                    />
                </label>

                <label>
                    Emergency Contact Name:{' '}
                    <input
                        value={contactName}
                        onChange={(e) =>
                            onChangeProfile({ contactName: e.target.value })
                        }
                    />
                </label>
                <label>
                    Emergency Contact Relationship:{' '}
                    <input
                        value={contactKind}
                        onChange={(e) =>
                            onChangeProfile({ contactKind: e.target.value })
                        }
                        placeholder="Mother, Father, etc..."
                    />
                </label>
                <label>
                    Emergency Contact Phone:{' '}
                    <input
                        onChange={(e) =>
                            onChangeProfile({ contactNumber: e.target.value })
                        }
                        placeholder="000-000-0000"
                        maxLength="14"
                    />
                    <span className="text-danger">{contactNumberError}</span>
                </label>

                <button onClick={onResetProfile}>Reset Values</button>
                <button onClick={onCancelClicked}>Cancel/Back</button>
                <button onClick={onSaveProfile}>Save Changes</button>
            </>
        ) : (
            <p>Loading...</p>
        )
    },
    userId
)

/*
            <label>domimantHand: <input value={domimantHand} onChange={e=> onChangeProfile({ domimantHand: e.target.value})} /></label>
            <label>Date of Birth: <input type="date" value={dob} onChange={e => {onChangeProfile({ dob: e.target.value }); validateDob(e)}} placeholder="MM/DD/YYYY" maxLength="10" />

            <div
                style={{ marginLeft: "10px", color: "black", width: "200px" }}
              >
            <Select isSearchable={true} options={options} value={value}
                onChange={changeHandler} />
            </div>
*/
